import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  List,
  Gallery,
  Image
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Individuelle Leadgenerierung - Echte Interessenten - Jetzt anfragen!"
      description="► Leadgenerierung aus Köln / Bonn: ✓ Aufbau eigener E-Mail-Verteiler ✓ Potenzielle Kunden gewinnen ✓ Konzept, Landingpage & Kampagne „aus einer Hand“"
    />
  	<Container>
  		<Container.Inner>
  			<Content>Leadgenerierung</Content>
        <Title as="h1">Gewinnen Sie echte Interessenten über eine individuelle Leadgenerierung</Title>
        <Content big>
          <p>Smarte Marketer wissen: Der Aufbau eigener E-Mail-Verteiler lohnt, ist aber nicht immer leicht. Eine zielgerichtete Leadgenerierung, die potenzielle Kunden für Ihr Produkt oder Ihre Dienstleistung anspricht, ist genau der richtige Weg zu mehr Kunden.</p>
          <Button as={Link} primary="true" to="/kontakt/">Leadgenerierung anfragen</Button>
        </Content>
		  </Container.Inner>
	  </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'limmaland').node} />
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5 flex-wrap-l">
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Gewinnen Sie Menschen, nicht nur E-Mail-Adressen</Title>
            <Content>
              <p>Wege an E-Mail-Adressen zu gelangen, gibt es viele. Nur wer aber genau seine Zielgruppe anspricht und ihr einen wirklichen Nutzen bietet, kann echte Interessenten und damit potenzielle Kunden gewinnen.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Aufbau eines eigenen E-Mail-Verteilers</Title>
            <Content>
              <p>Ich unterstütze Sie bei dem Aufbau eines eigenen E-Mail-Verteilers durch individuelle Kampagnen. Gekaufte Adressen, die in vielen Pools gleichzeitig vorhanden sind, und nicht wirklich an ihrem Unternehmen interessiert sind, halte ich für den falschen Weg.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Konzept, Landingpage und Kampagne „aus einer Hand“</Title>
            <Content>
              <p>Individuelle Leadgenerierung, von der Idee bis zur ersten E-Mail-Adresse, und darüber hinaus. Konzept, Landingpage und Kampagne erhalten Sie auf Wunsch aus einer Hand.</p>
            </Content>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5 flex-wrap-l items-center-l">
          <Grid.Col className="mb5 w-50-l order-2-l">
            <Title small>Native Advertising</Title>
            <Content>
              <p>Über Native Advertising können Sie potenzielle Interessenten im redaktionellen Kontext großer und kleiner Online-Publikationen erreichen. Targeting und Anzeigen werden optimal auf Landingpage und gewünschte Zielgruppe abgestimmt.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l order-1-l">
            <Gallery>
              <Gallery.Item><Image as={Img} fixed={data.outbrainLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.plistaLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.taboolaLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.googleAdsLogo.childImageSharp.fixed} /></Gallery.Item>
            </Gallery>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Contact>
      <Title center>Bauen Sie jetzt Ihr E-Mail-Marketing auf, mit echten Interessenten</Title>
      <Content big center>
        <p>Durch eine individuelle Leadgenerierung mit Konzept, Landingpage und Kampagne aus einer Hand erhalten potenzielle Kunden für Ihre Dienstleistung oder Ihr Produkt.</p>
        <Button as={Link} primary="true" to="/kontakt/">Leadgenerierung anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "limmaland"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
    outbrainLogo: file(relativePath: { eq: "outbrain.png" }) {
      ...logoFixed
    }
    plistaLogo: file(relativePath: { eq: "plista.png" }) {
      ...logoFixed
    }
    taboolaLogo: file(relativePath: { eq: "taboola.png" }) {
      ...logoFixed
    }
    googleAdsLogo: file(relativePath: { eq: "google-ads.png" }) {
      ...logoFixed
    }
  }
`
